/* src/components/ContactUs.css */
.contact-us-container {
    font-family: Roboto, Arial, sans-serif !important;
  }
  
  .contact-us-container h1,
  .contact-us-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-us-container p {
    color: #666;
    line-height: 1.6;
  }
  
  .contact-us-container .text-center {
    text-align: center;
  }
  
  .contact-us-container .mb-4 {
    margin-bottom: 1.5rem;
  }

  /* From Uiverse.io by yaasiinaxmed */ 
.sty-button {
  --color: #1366db;
  font-family: inherit;
  display: inline-block;
  width: 12em;
  height: 2.6em;
  line-height: 2.5em;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  z-index: 1;
  color: var(--color);
  border: 2px solid var(--color);
  border-radius: 6px;
  position: relative;
}

.sty-button::before {
  position: absolute;
  content: "";
  background: var(--color);
  width: 350px;
  height: 450px;
  z-index: -1;
  border-radius: 50%;
}

.sty-button:hover {
  color: white;
}

.sty-button:before {
  top: 100%;
  left: 100%;
  transition: 0.5s all;
}

.sty-button:hover::before {
  top: -60px;
  left: -70px;
}

  
  
  